import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Company, CompanyName, CompanyContactInfo } from '../models/models';

// export interface ICompanyService {
//     getCompanies():Observable<Company[]>;

//     getCompany(companyId:string):Observable<Company>;

//     createCompany(company:Company):Observable<any>;

//     updateCompany(companyId: string, updatedCompany:Company):Observable<any>;

//     deleteCompany(companyId):Observable<any>;
// }

@Injectable()
export abstract class ICompanyService  {
    abstract getCompanies(): Observable<Company[]>;

    abstract getCompany(companyId: string): Observable<Company>;

    abstract createCompany(company: Company): Observable<any>;

    abstract updateCompany(id: string, updatedCompany: Company): Observable<any>;

    abstract deleteCompany(companyId): Observable<any>;

    abstract getCompanyCategories(): Observable<string[]>;

    abstract getCompanyNames(): Observable<CompanyName[]>;

    abstract getCompanyNamesForUser(): Observable<CompanyName[]>;

    abstract getVendorCompanyNames(): Observable<CompanyName[]>;

    abstract getConsumerCompanyNames(): Observable<CompanyName[]>;

    abstract getNonMigratedCompanyNames(): Observable<CompanyName[]>;

    abstract getCreatedAfterOrMigratedCompanyNames(): Observable<CompanyName[]>;

    abstract getConsumerCreatedAfterOrMigratedCompanyNames(): Observable<CompanyName[]>;

    abstract getCompanyContactInfo(companyId: string): Observable<CompanyContactInfo>;
}
