import { Component } from '@angular/core';

@Component({
  selector: 'app-api-help',
  templateUrl: './api-help.component.html',
  styleUrls: ['./api-help.component.css']
})
export class ApiHelpComponent {
  sampleData = `[
  {
    "deviceId": "DEVICE001",
    "sensorName": "Temperature",
    "sensorValue": 75.5,
    "timestamp": "2024-01-20T15:30:00Z"
  },
  {
    "deviceId": "DEVICE001", 
    "sensorName": "Pressure",
    "sensorValue": 1000,
    "timestamp": "2024-01-20T15:30:00Z"
  }
]`;
}
