<div class="field grid col-12" *ngIf="job$ | async">
  <!--Page Title and Dashboard Link-->
  <div class="col-4">
    <h2>Service Provider Job Details</h2>
  </div>

  <div class="col-8">
    <ng-container *ngIf="!isNew">
      <div class="flex justify-content-end gap-2 mt-3">
        <button pButton 
          class="h-2rem w-10rem"
          (click)="republishWorkbook()"
          icon="fa fa-retweet fa-lg" 
          label="Republish">
        </button>
        <button pButton 
          class="h-2rem w-10rem"
          (click)="showManageFiles = true" 
          icon="fa fa-file" 
          label="Files">
        </button>
        <button pButton 
          class="h-2rem w-10rem"
          (click)="navigateToWorkbook()" 
          icon="fa fa-book" 
          label="Workbook">
        </button>
        <button pButton 
          class="h-2rem w-10rem"
          (click)="navigateToDashboard()" 
          icon="far fa-chart-bar" 
          label="Dashboard">
        </button>
      </div>
    </ng-container>
  </div>

  <div class="field grid col-12">
    <label class="col-2">Job Number</label>
    <div class="col-10">
      <input type="text" pInputText placeholder="Enter Job Number" [(ngModel)]="jobToUpdate.name" [disabled]="!isNew" />
      <div *ngIf="modelInvalid && !jobToUpdate.name">
        <label style="color:red; font-weight:bold;">Id is required!</label>
      </div>
    </div>
  </div>

  <!-- Vendor Company -->
  <div class="field grid col-12" *ngIf="vendorCompanies$ | async as vendorCompanies" >
    <label class="col-2">Service Provider (Vendor)</label>
    <div class="col-10">
      <p-dropdown [options]="vendorCompanies" placeholder="Must Be A Service Provider"
        [(ngModel)]="jobToUpdate.companyId" (onChange)="setServiceProvider()" [filter]="true" filterBy="label"
        [disabled]="true">
      </p-dropdown>
      <div *ngIf="modelInvalid && invalidCompanyId">
        <label style="color:red; font-weight:bold;">User must belong to Service Provider</label>
      </div>
    </div>
  </div>




  <!-- Consumer Company -->
  <div class="field grid col-12" *ngIf="consumerCompanies$ | async as consumerCompanies" >
    <label class="col-2">Operator (Consumer)</label>
    <div class="col-10">
      <p-dropdown id="consumerCompaniesDropDown" [options]="consumerCompanies" optionLabel="label" optionValue="value" [(ngModel)]="jobToUpdate.consumerCompanyId" >
      </p-dropdown>

      <div *ngIf="modelInvalid && !jobToUpdate.consumerCompanyId">
        <label style="color:red; font-weight:bold;">Operator is required!</label>
      </div>
    </div>
    <div class="ui-g-1">
      <i *ngIf="jobToUpdate.consumerCompanyId" class="fas fa-info-circle clear-end-time"
        (mouseover)="showContactInfo()"></i>
    </div>
  </div>

  <!-- Other Service Providers -->
  <div *ngIf="!isUserServiceProvider" class="field grid col-12">

    <label class="col-2">OFS Shared With</label>
    <div class="col-10">
      <p-multiSelect *ngIf="serviceProviderOptions$ | async as serviceProviderOptions" [options]="serviceProviderOptions"
        placeholder="Service Providers" [(ngModel)]="jobToUpdate.serviceProviderIds" [showClear]="true" ></p-multiSelect>
    </div>
  </div>

  <!-- Well Name -->
  <div class="field grid col-12">

    <label class="col-2">Well Name</label>
    <div class="col-10">
      <input type="text" pInputText placeholder="Enter WellName" [(ngModel)]="jobToUpdate.wellInfo.name"
        [disabled]="!isNew" />
      <div *ngIf="modelInvalid && (!jobToUpdate.wellInfo.name && !jobToUpdate.apiNumber)">
        <label style="color:red; font-weight:bold;">Well Name or Api Number is required!</label>
      </div>
    </div>
  </div>

  <!-- Api Number -->
  <div class="field grid col-12">
    <label class="col-2">Api Number</label>

    <div class="col-10">
      <p-inputMask pInputText mask="99-999-99999" [(ngModel)]="jobToUpdate.apiNumber"
        placeholder="99-999-99999"></p-inputMask>
      <div *ngIf="modelInvalid && (!jobToUpdate.wellInfo.name && !jobToUpdate.apiNumber)">
        <label style="color:red; font-weight:bold;">Well Name or Api Number is required!</label>
      </div>
    </div>
  </div>

  <!-- Job Type -->
  <div class="field grid col-12">
    <label class="col-2">Job Type</label>

    <div class="col-10">
      <p-dropdown [options]="jobTypes" placeholder="Select Job Type" [(ngModel)]="jobToUpdate.workbookType"
        [disabled]="!isNew"></p-dropdown>
      <div *ngIf="modelInvalid && !jobToUpdate.workbookType">
        <label style="color:red; font-weight:bold;">Job Type is required!</label>
      </div>
    </div>
  </div>

  <!-- Start & End Time -->
  <div class="field grid col-12">

    <label class="col-2">Arrive on Loc/Begin RU on Well</label>
    <div class="col-10">
      <p-calendar [(ngModel)]="jobToUpdate.startTime" [showIcon]="true" [showTime]="true" [hourFormat]="'24'"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2038">
      </p-calendar>
      <div *ngIf="modelInvalid && !jobToUpdate.startTime">
        <label style="color:red; font-weight:bold;">Start Time is required!</label>
      </div>
    </div>
  </div>

  <div class="field grid col-12">
    <label class="col-2">Release from Loc/RD from Well </label>
    <div class="col-10">
      <p-calendar [(ngModel)]="jobToUpdate.endTime" [showIcon]="true" [showTime]="true" [hourFormat]="'24'"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2038">
      </p-calendar>
      <i *ngIf="jobToUpdate.endTime" class="far fa-trash-alt clear-end-time" (click)="clearEndDate()"
        pTooltip="Clear Release from Loc/RD from Well Time"></i>
    </div>
  </div>

  @if (canLinkJob(this.jobToUpdate)) {
  <!-- Job Linking -->
  <div *ngIf="consumerCompanies$ | async as consumerCompanies" class="field grid col-12">
    <app-job-linker *ngIf="isNew" [job]="jobToUpdate" [companySelectItems]="consumerCompanies"
      ></app-job-linker>
    <app-job-linker *ngIf="!isNew" [vendorJobId]="jobToUpdate.id" [companySelectItems]="consumerCompanies"
      ></app-job-linker>
  </div>

}
  <!-- DeviceList -->
  <div *ngIf="jobToUpdate" class="field grid col-12">
    <label class="col-2">Devices</label>
    <div class="col-10">
      <div class="flex align-items-center gap-2 flex-wrap">
        <p-chip *ngFor="let device of getMappedDeviceList(jobToUpdate) | async" 
                [label]="device.name"
                [removable]="true"
                (onRemove)="removeDevice(device.id)">
        </p-chip>
        <button pButton 
          type="button" 
          icon="pi pi-plus" 
          class="p-button-sm" 
          (click)="showDeviceDialog = true"
          pTooltip="Add Device">
        </button>
      </div>
    </div>
  </div>

  @if (jobToUpdate && user$ | async; as user) {
    
  <div *ngIf="!isNew && isEditorOrAdmin(user, this.jobToUpdate.companyId)" class="field grid col-12">
    <!-- Job Exports -->
    <div class="col-2">
      <label>Available Exports</label>
    </div>
    <div class="col-2">
      <button pButton  icon="fa fa-download" label="Job Data" (click)="downloadJobExport()"
        style="max-height:32px; width:100%; padding-top:3px; padding-bottom:5px;"></button>
    </div>
    <div class="col-2">
      <button pButton  icon="fa fa-download" label="Job Summary"
        (click)="downloadJobSummaryExport()"
        style="max-height:32px; width:100%; padding-top:3px; padding-bottom:5px;"></button>
    </div>
  </div>
  <!-- Sand Report -->
  <div *ngIf="!isNew && isEditorOrAdmin(user, this.jobToUpdate.companyId) && jobToUpdate.workbookType == 'Flowback/Well Testing'"
    class="field grid col-12">
    <div class="col-2">
      <label>Sand Report</label>
    </div>
    <div class="col-3">
      <p-calendar [(ngModel)]="sandReportBeginTime" [showIcon]="true" [showTime]="true" [hourFormat]="'24'"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2038">
      </p-calendar>
    </div>

    <div class="col-3">
      <p-calendar [(ngModel)]="sandReportEndTime" [showIcon]="true" [showTime]="true" [hourFormat]="'24'"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2038">
      </p-calendar>
    </div>
    <div class="col-4">
      <button pButton  icon="fa fa-download" label="Sand Report" (click)="downloadSandReport()"
        style="max-height:32px; width:100%; padding-top:3px; padding-bottom:5px;"></button>
    </div>
  </div>

  <!-- Data Sheet Report -->
  <div *ngIf="!isNew && isEditorOrAdmin(user, this.jobToUpdate.companyId) && jobToUpdate.workbookType == 'Flowback/Well Testing'"
    class="field grid col-12">
    <div class="col-2">
      <label>Data Sheet Report</label>
    </div>
    <div class="col-3">
      <p-calendar [(ngModel)]="dataSheetReportBeginTime" [showIcon]="true" [showTime]="true" [hourFormat]="'24'"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2038">
      </p-calendar>
    </div>

    <div class="col-3">
      <p-calendar [(ngModel)]="dataSheetReportEndTime" [showIcon]="true" [showTime]="true" [hourFormat]="'24'"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2038">
      </p-calendar>
    </div>
    <div class="col-4">
      <button pButton  icon="fa fa-download" label="Data Sheet"
        (click)="downloadDataSheetReport()"
        style="max-height:32px; width:100%; padding-top:3px; padding-bottom:5px;"></button>
    </div>
  </div>
  }
  <div class="col-12">
    <app-vendor-job-common [(ngModel)]="jobToUpdate" [modelInvalid]="modelInvalid" [disabled]="disabled"
      [showWorkbookLink]="false"></app-vendor-job-common>
  </div>
  <div class="col-12">
    <p-accordion [multiple]="false" *ngIf="company$ | async as company"  >
      <p-accordionTab header="Workbook Configuration">
        <app-workbook-configuration [companyId]="jobToUpdate.companyId" [jobId]="jobToUpdate.id"
          [jobType]="jobToUpdate.workbookType" [companyUnitSystem]="company.unitSystem"
          (jobConfiguration)="updateWorkbookConfiguration($event)" (errors)="handleErrorsFromConfiguration($event)">
        </app-workbook-configuration>
      </p-accordionTab>
    </p-accordion>
  </div>

  <!-- Action Buttons -->
  <div class="col-12">
    <button pButton *ngIf="isNew" (click)="saveJob()" [disabled]="saving" [ngClass]="{ 'action-button': !saving,
                         'action-button-disabled': saving }">
      Create
    </button>
    <button pButton *ngIf="!isNew" (click)="saveJob()" [disabled]="saving || isUserServiceProvider" [ngClass]="{ 'action-button': !saving,
                         'action-button-disabled': saving }">
      Update
    </button>
    <button pButton (click)="returnToJobList()" class="action-button">
      Cancel
    </button>
  </div>



</div>

<!--Invalid Model Dialog-->
<p-dialog header="Cannot Save Changes" [(visible)]="showModelInvalidDialog" [modal]="true" [style]="{width: '350px'}"
  [minY]="70" [baseZIndex]="999" [autoZIndex]="false" [dismissableMask]="true">
  <span>
    One or more fields is empty or invalid. Please correct and try again.
  </span>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="closeInvalidModelDialog()" label="Ok"></button>
  </p-footer>
</p-dialog>

<!-- ContactInfo Dialog -->
<p-dialog *ngIf="jobToUpdate" header="Company Contact Information" [(visible)]="showCompanyContactInfoDialog">
  <app-contact-info [(ngModel)]="jobToUpdate.consumerCompanyId"></app-contact-info>
</p-dialog>

<!-- Files Dialog -->
<p-dialog *ngIf="showManageFiles" header="Job Files" [(visible)]="showManageFiles">
  <app-filelist [consumerJobId]="jobToUpdate.consumerJobId"></app-filelist>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="showManageFiles = false" label="Close"></button>
  </p-footer>
</p-dialog>

<!-- Device Selection Dialog -->
<p-dialog header="Select Device" [(visible)]="showDeviceDialog" [modal]="true" [style]="{width: '100vw', maxWidth: '900px'}">
  @if (userCompanyDeviceMaps$ | async; as userCompanyDeviceMaps) {
     <p-table #dt 
           [value]="filterDevices(userCompanyDeviceMaps)"
           [scrollable]="true" 
           scrollHeight="400px"
           [globalFilterFields]="['deviceName','type','jobName']"
           [sortField]="'deviceName'"
           [sortOrder]="1"
           [tableStyle]="{'min-width': '50rem'}"
           [filterDelay]="0">
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between align-items-center">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" 
                 (input)="dt.filterGlobal($event.target.value, 'contains')" 
                 placeholder="Search devices..." />
        </span>
        <span>
          <p-checkbox [(ngModel)]="showOnlyUnassigned" 
                      [binary]="true" 
                      label="Show only unassigned devices">
          </p-checkbox>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="deviceName">Device Name<p-sortIcon field="deviceName"></p-sortIcon></th>
        <th pSortableColumn="deviceType">Type<p-sortIcon field="deviceType"></p-sortIcon></th>
        <th pSortableColumn="jobName">Current Job<p-sortIcon field="jobName"></p-sortIcon></th>
        <th pSortableColumn="jobStartDate">Job Start<p-sortIcon field="jobStartDate"></p-sortIcon></th>
        <th pSortableColumn="jobEndDate">Job End<p-sortIcon field="jobEndDate"></p-sortIcon></th>
        <th>Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-device>
      <tr>
        <td>{{device.deviceName}}</td>
        <td>{{device.deviceType}}</td>
        <td>{{device.jobName || 'Not Assigned'}}</td>
        <td>{{device.jobStartDate | date:'short'}}</td>
        <td>{{device.jobEndDate | date:'short'}}</td>
        <td>
          <button pButton 
                  *ngIf="!device.jobId"
                  type="button" 
                  label="Assign" 
                  class="p-button-sm"
                  [disabled]="jobToUpdate?.deviceList?.includes(device.deviceId)"
                  (click)="addDevice(device.deviceId)">
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
}
</p-dialog>

<!-- ...existing code... -->