import { Injectable } from '@angular/core';
import { Observable, of, throwError, timer } from 'rxjs';
import { map, shareReplay, catchError, retry } from 'rxjs/operators';
import { IUserManagerService } from './iUserManager.service';
import { ICurrentUserService } from './currentUserService/icurrentuser.service';
import { User, UserClient } from './nswag/nswagclient';

import { UserRoles } from '../models/userRole';
import { TelemetryService } from './telemetry.service';

@Injectable({
    providedIn: 'root'
  })
export class UserManagerService extends IUserManagerService{
    constructor(private _userClient: UserClient, private _currentUserService:ICurrentUserService, private _telementry: TelemetryService) {
        super();
    }
    private _user$:Observable<User>;
    getCurrentUser():Observable<User>{
        if (this._user$) return this._user$; 
        let email = this._currentUserService.getUserEmail();
        if (email == null){
            return of(null);
        }
        this._user$ = this._userClient.addUserIfNeeded(email).pipe(
            retry({
                count: 3,
                delay: (error, retryCount) => {
                    console.warn(`Retry attempt ${retryCount}`);
                    this._telementry.trackTrace({message: `Retry addUserIfNeeded attempt ${retryCount} error ${error}`});
                    return timer(1000 * Math.pow(2, retryCount)); // 1s, 2s, 4s
                }
            }),
            shareReplay(1),
            catchError(err => {
                console.error('Failed after retries:', err);
                this._telementry.trackTrace({message: `Failed after retries: ${err}`});
                return throwError(() => err);
            })
        );

        return this._user$;
    }
    isUserSuperAdmin():Observable<boolean>{
        return this.getCurrentUser().pipe(map(u=>{
            return u.isGlobalAdmin;
        }));
    }

    getIsEditorOrAdmin():Observable<boolean> {
        return this.getCurrentUser().pipe(map(u=>{
            if (u.isGlobalAdmin) return true;
            if (u.companyRoles) {
                u.companyRoles.forEach(r => {
                    if (r.role === UserRoles.admin || r.role === UserRoles.editor) {
                      return true;
                    }
                });
            }
            return false;
        }));
      }
}