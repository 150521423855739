<div class="card">
  <h2>API Documentation</h2>

  <h3>Sensor Data Ingestion</h3>
  <p>To send sensor data to the system, make a POST request to the following endpoint:</p>

  <div class="code-block">
    <code>POST https://opsprodintegration.azurewebsites.net/api/IngestSensor?apiKey=YOUR_API_KEY</code>
  </div>

  <h4>Request Format</h4>
  <p>The request body should be a JSON array of sensor readings with the following structure:</p>

  <div class="code-block">
    <pre><code>{{sampleData}}</code></pre>
  </div>

  <h4>Fields</h4>
  <ul>
    <li><strong>deviceId</strong>: The ID of the device sending data (required)</li>
    <li><strong>sensorName</strong>: Name of the sensor measurement (required)</li>
    <li><strong>sensorValue</strong>: The sensor reading value (required)</li>
    <li><strong>timestamp</strong>: ISO 8601 formatted timestamp (e.g. "2024-01-20T15:30:00Z") (required)</li>
  </ul>

  <h4>Device Management</h4>
  <p>Before sending data, devices must be created and associated with your company in the system. 
     You can manage your devices at <a href="/devices" target="_blank">Device Management</a>.</p>
  <p>When creating a new device, only the following fields are required:</p>
  <ul>
    <li><strong>Id</strong>: The device identifier that will be used in API calls</li>
    <li><strong>Name</strong>: A display name for the device</li>
    <li><strong>Category</strong>: Can be set to "Ultralite"</li>
  </ul>
  <p class="p-warning"><i class="pi pi-info-circle"></i> Data sent for unknown devices will be rejected with a 400 Bad Request response.</p>

  <h4>Authentication</h4>
  <p>Include your API key as a query parameter in the URL. You can find your API key on the company details page.</p>

  <h4>Response Codes</h4>
  <ul>
    <li><strong>200 OK</strong>: Data successfully ingested</li>
    <li><strong>400 Bad Request</strong>: Invalid request format or missing required fields</li>
    <li><strong>401 Unauthorized</strong>: Invalid API key</li>
  </ul>
</div>
