import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { FieldsetModule } from 'primeng/fieldset';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {FileUploadModule} from 'primeng/fileupload';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { CasingTubingComponent } from './components/shared/casing-tubing/casing-tubing.component';
import { PlugsComponent } from './components/shared/plugs/plugs.component';
import { FilelistComponent } from './components/files/filelist/filelist.component';
import { TableModule } from 'primeng/table';
import { GlobalFilesContainerComponent } from './components/files/global-files-container/global-files-container.component';
import { FileNamePipe } from './components/files/fileName.pipe';
import {ToastModule} from 'primeng/toast';
import { InputMaskModule } from 'primeng/inputmask';
import { TicketListComponent } from './components/ticket-system/ticket-list/ticket-list.component';
import { ConsumerJobClient } from './services/nswag/nswagclient';
import { IUserManagerService } from './services/iUserManager.service';
import { UserManagerService } from './services/userManager.service';
import { ChipModule } from 'primeng/chip';
@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        DropdownModule,
        FieldsetModule,
        OverlayPanelModule,
        ButtonModule,
        InputTextModule,
        FileUploadModule,
        AutoCompleteModule,
        TableModule,
        ToastModule,
        InputMaskModule, 
        ConfirmPopupModule
    ],
    declarations: [
        CasingTubingComponent,
        PlugsComponent,
        FileNamePipe,
        FilelistComponent,
        GlobalFilesContainerComponent,
        TicketListComponent
    ],
    exports: [
        CasingTubingComponent,
        PlugsComponent,
        FileNamePipe,
        FilelistComponent
    ],
    providers: [
        ConfirmationService,
       {provide: "IConsumerJobClient", useClass: ConsumerJobClient},
    ],
})
export class SharedModule {}