<!-- Add Plugs section -->


  <div class="grid field col-12">
    <div class="col-12 md:col-9">
      <p>Add plugs of the same type, manufacturer, and model:</p>
    </div>
    <div class="col-12 md:col-2">
      <label>Number of Plugs</label>
    </div>
    <div class="col-12 md:col-1">
      <label class="readOnly-label">{{getTotalPlugs()}}</label>
    </div>
  </div>


  <div class="grid field col-12">
    <!-- Manufacture Choice -->
    <div class="col-12 md:col-2">
      <label>Manufacturer</label>
    </div>
    <div class="col-12 md:col-4">
      <input type="text" pInputText placeholder="Enter Manufacturer" [(ngModel)]="plugsToAddModel.manufacturer" [disabled]="disabled"/>
      <div *ngIf="addPlugsModelInvalid && !plugsToAddModel.manufacturer" class="grid field col-12">
        <label style="color:red; font-weight:bold;">Manufacturer is required!</label>
      </div>
    </div>
    <!-- Model Choice -->
    <div class="col-12 md:col-2">
      <label>Model</label>
    </div>
    <div class="col-12 md:col-4">
      <input type="text" pInputText placeholder="Enter Model" [(ngModel)]="plugsToAddModel.model" [disabled]="disabled"/>
      <div *ngIf="addPlugsModelInvalid && !plugsToAddModel.model" class="grid field col-12">
        <label style="color:red; font-weight:bold;">Model is required!</label>
      </div>
    </div>
  </div>

  <div class="grid field col-12">
    <!-- Type Choice -->
    <div class="col-12 md:col-2">
      <label>Type</label>
    </div>
    <div class="col-12 md:col-4">
      <p-dropdown [options]="plugTypeOptions" [(ngModel)]="plugsToAddModel.type" placeholder="Select Type"
        [style]="{'width':'100%'}" [disabled]="disabled">
      </p-dropdown>
      <div *ngIf="addPlugsModelInvalid && !plugsToAddModel.type" class="grid field col-12">
        <label style="color:red; font-weight:bold;">Type is required!</label>
      </div>
    </div>
    <!-- Number to Add -->
    <div class="col-12 md:col-2">
      <label>Number</label>
    </div>
    <div class="col-12 md:col-4">
      <input type="number" pInputText placeholder="Enter Amount" [(ngModel)]="plugsToAddModel.count" 
        min="0" max="300" [disabled]="disabled"/>
      <div *ngIf="addPlugsModelInvalid && !plugsToAddModel.count && plugsToAddModel.count != 0" class="grid field col-12">
        <label style="color:red; font-weight:bold;">Amount of Plugs is required!</label>
      </div>
      <div *ngIf="addPlugsModelInvalid && plugsToAddModel.count != null && (plugsToAddModel.count <= 0 || plugsToAddModel.count > 300)" class="grid field col-12">
        <label style="color:red; font-weight:bold;">Amount of Plugs must be between 1 and 300!</label>
      </div>
    </div>
  </div>
  <div class="grid field col-12">
    <button pButton  (click)="addPlugSet()" icon="fa fa-plus" label="Plug Group"
      class="add-plugs-btn" [disabled]="disabled">
    </button>
  </div>
  <div class="grid field col-12">
    <div class="col-12 md:col-10">
        <textarea type="text" pInputTextarea class="import-text-area" [rows]="3" autoResize="autoResize" 
          placeholder="Paste Data From Excel" [(ngModel)]="plugsToAddModel.importData" (mouseenter)="importTips.show($event)" [disabled]="disabled"></textarea>
    </div>
    <div class="col-12 md:col-2">
      <button pButton  (click)="importData()" icon="fa fa-upload" label="Import"
        class="import-btn" [disabled]="disabled">
      </button>
    </div>
  </div>


<!-- Plugs listing Section -->
<div class="grid field col-12">

  <div *ngFor="let plugSet of plugSets" class="col-12 plug-group">
    <div class="grid field col-12">
      <!-- Manufacture Choice -->
      <div class="col-12 md:col-2">
        <label>Manufacturer</label>
      </div>
      <div class="col-12 md:col-3">
        <input type="text" pInputText placeholder="Enter Manufacturer" [(ngModel)]="plugSet.manufacturer" [disabled]="disabled"/>
      </div>
      <!-- Model Choice -->
      <div class="col-12 md:col-1">
        <label>Model</label>
      </div>
      <div class="col-12 md:col-3">
        <input type="text" pInputText placeholder="Enter Model" [(ngModel)]="plugSet.model" [disabled]="disabled"/>
      </div>
      <!-- Type Choice -->
      <div class="col-12 md:col-1">
        <label>Type</label>
      </div>
      <div class="col-12 md:col-2">
        <p-dropdown [options]="plugTypeOptions" [(ngModel)]="plugSet.type" placeholder="Select Type"
          [style]="{'width':'100%'}" [disabled]="disabled">
        </p-dropdown>
      </div>
    </div>
      
    <!-- Add Plug to Group -->
    <div class="col-12 md:col-6">
      <button pButton  (click)="addPlugToPlugSet(plugSet)" icon="fa fa-plus" label="New Plug to Group"
        class="add-plugs-btn" [disabled]="disabled">
      </button>
    </div>
    <!-- Remove Plug Group -->
    <div class="col-12 md:col-6">
      <button pButton  (click)="deletePlugSet(plugSet)" icon="far fa-trash-alt" label="Remove Plug Group"
        class="add-plugs-btn" [disabled]="disabled">
      </button>
    </div>

    <!-- Plugs -->
    <div *ngFor="let plug of plugSet.plugs" class="grid field col-12">
      <div class="col-12 md:col-2">
        <label class="">Plug {{plug.number}}:</label>
      </div>
      <div class="col-12 md:col-1">
        <label>Depth</label>
      </div>
      <div class="col-12 md:col-2">
        <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="plug.depth" [disabled]="disabled"/>
      </div>
      <div class="col-12 md:col-1">
        <label>Perf. Top</label>
      </div>
      <div class="col-12 md:col-2">
        <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="plug.perfTop" [disabled]="disabled"/>
      </div>
      <div class="col-12 md:col-1">
        <label>Perf. Bottom</label>
      </div>
      <div class="col-12 md:col-2">
        <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="plug.perfBottom" [disabled]="disabled"/>
      </div>
      <div class="col-12 md:col-1">
        <i class="far fa-trash-alt" (click)="confirmDeletePlug(plug.number)"></i>
      </div>
    </div>
  </div>
</div>

<p-overlayPanel #importTips appendTo="body">
  <div class="ui-g import-tips">
    <div class="grid field col-12">
      <p>Import Data by copying from Excel in one the following two formats.  If plug groups are already defined, 
        only import the Depth, PerfTop, or PerfBottom data.  If groups are not defined, 
        import plug group information on each row to the build plug groups at the same time.</p>
      <p>Copy only data (no table header information)</p>
    </div>
    <table class="grid field col-12">
      <tr class="left-text">
        <th>Depth</th>
        <th>PerfTop</th>
        <th>PerfBottom</th>
      </tr>
      <tr>
        <td>1500</td>
        <td>1525</td>
        <td>1575</td>
      </tr>
      <tr>
        <td>2000</td>
        <td>2025</td>
        <td>2075</td>
      </tr>
      <tr>
        <td>2500</td>
        <td>2525</td>
        <td>2575</td>
      </tr>
    </table>
    <div class="grid field col-12">OR</div>
    <table class="grid field col-12">
      <tr class="left-text">
        <th>Manufacturer</th>
        <th>Model</th>
        <th>Type</th>
        <th>Depth</th>
        <th>PerfTop</th>
        <th>PerfBottom</th>
      </tr>
      <tr>
        <td>Manufacturer 1</td>
        <td>Model 1</td>
        <td>Type 1</td>
        <td>1500</td>
        <td>1525</td>
        <td>1575</td>
      </tr>
      <tr>
        <td>Manufacturer 1</td>
        <td>Model 1</td>
        <td>Type 1</td>
        <td>2000</td>
        <td>2025</td>
        <td>2075</td>
      </tr>
      <tr>
        <td>Manufacturer 2</td>
        <td>Model 2</td>
        <td>Type 2</td>
        <td>2500</td>
        <td>2525</td>
        <td>2575</td>
      </tr>
    </table>
  </div>
</p-overlayPanel>