<div class="form-container">
    <div>
        <h2>Company Detail</h2>
    </div>
    <div *ngIf="messages.length > 0">
        <p-messages [(value)]="messages"></p-messages>
    </div>
    <div *ngIf="!everythingReady || saving">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="everythingReady" class="grid">
        <!-- Company Properties -->
        <div class="col-12">
            <div class="grid formgrid p-fluid">
                <!-- Name -->
                <div class="col-3 field">
                    <label>Name</label>
                </div>
                <div class="col-9 field">
                    <input type="text" pInputText placeholder="Enter Company Name" [(ngModel)]="company.name" />
                    <div *ngIf="modelInvalid && !company.name">
                        <small class="p-error">Name is required!</small>
                    </div>
                </div>

                <!-- Prefix -->
                <div class="col-3 field">
                    <label>Prefix</label>
                </div>
                <div class="col-9 field">
                    <input type="text" pInputText placeholder="Enter Company Prefix" [(ngModel)]="company.prefix" />
                </div>

                <!-- Company Type -->
                <div class="col-3 field">
                    <label>Company Type</label>
                </div>
                <div class="col-9 field">
                    <p-dropdown [options]="companyTypes" placeholder="Select Company Type" [(ngModel)]="company.companyType"
                        (onChange)="setAllowedToBeServiceProvider()" styleClass="w-full"></p-dropdown>
                    <div *ngIf="modelInvalid && !company.companyType">
                        <small class="p-error">Company Type is required!</small>
                    </div>
                </div>

                <!-- OFS Shared With -->
                <div class="col-3 field">
                    <label>OFS Shared With</label>
                </div>
                <div class="col-9 field">
                    <p-multiSelect [options]="serviceProviderOptions" placeholder="Service Providers"
                        [(ngModel)]="company.serviceProviderIds" styleClass="w-full"></p-multiSelect>
                </div>

                <!-- Slack Webhook -->
                <div class="col-3 field">
                    <label>Slack Webhook</label>
                </div>
                <div class="col-9 field">
                    <input type="text" pInputText placeholder="Enter Slack Webhook" [(ngModel)]="company.slackWebhook"
                        pTooltip="Optional. Integrate messages with your company's Slack account." />
                </div>

                <!-- Auto Approved Emails -->
                <div class="col-3 field">
                    <label>Auto Approved Email Domains</label>
                </div>
                <div class="col-9 field">
                    <input type="text" pInputText placeholder="Enter Email Domains (separated by semicolon)"
                        [(ngModel)]="autoApprovedEmailDomains" (change)="updateEmailDomains()"
                        pTooltip="Email domains separated by a semicolon that will auto approve user requests coming from users whose sign in id is part of the listed domains." />
                </div>

                <!-- Api Key -->
                <div class="col-3 field">
                    <label>Api Key</label>
                    <button pButton class="p-button-text" icon="pi pi-question-circle" (click)="showApiHelp()" pTooltip="View API Documentation"></button>
                </div>
                <div class="col-7 field">
                    <input type="text" pInputText placeholder="Generate API Key" disabled="true" [(ngModel)]="company.apiKey" />
                </div>
                <div class="col-2 field">
                    <button pButton (click)="generateApiKey()" label="Generate" pTooltip="Generate a new ApiKey"  ></button>
                </div>
            </div>
        </div>

        <div class="col-12">
            <p-accordion>
                <!-- Workbook Configuration -->
                <p-accordionTab *ngIf="offlineWorkbookFeatureEnabled" header="Workbook Configuration" [selected]="true">
                    <div class="grid formgrid">
                        <!-- Enable Workbook Features -->
                        <!-- <div class="col-3">
                            <label>Enable New Workbook Features?</label>
                        </div>
                        <div class="col-9"
                            pTooltip="Check this option to configure the company for the new Company Configuration and Offline Workbook Feature.  All NEW jobs will use this feature if checked.">
                            <p-checkbox [(ngModel)]="company.enableOfflineWorkbook" binary="true" [disabled]="true">
                            </p-checkbox>
                        </div> -->

                        <!-- Company Unit System -->
                        <div class="col-3">
                            <label>Company Unit System</label>
                        </div>
                        <div class="col-3">
                            <p-dropdown [options]="workbookConfigurationUnitSystemChoices"
                                placeholder="Select Company Unit System" [(ngModel)]="company.unitSystem"
                                styleClass="w-full"></p-dropdown>
                        </div>
                        <div class="col-6">
                            <button pButton *ngIf="isAdmin" (click)="RecreateWorkbookConfiguration()"
                                [ngClass]="{ 'action-button': !saving, 'action-button-disabled': saving }"
                                [disabled]="saving" label="Reset Configuration"></button>
                        </div>
                    </div>

                    <div class="col-12">
                        <app-workbook-configuration [companyUnitSystem]="company.unitSystem" [companyId]="company.id"
                            [showUnitSystemChoices]="false" (companyConfiguration)="updateWorkbookConfiguration($event)"
                            (errors)="handleErrors($event)">
                        </app-workbook-configuration>
                    </div>

                </p-accordionTab>

                <p-accordionTab *ngIf="company.enableOfflineWorkbook" header="Calculations">

                    <app-calculations [companyId]="company.id" (companyCalculations)="updateCompanyCalculations($event)"
                        (errors)="handleErrors($event)">
                    </app-calculations>
                </p-accordionTab>

                <p-accordionTab *ngIf="company.enableOfflineWorkbook" header="Report Configuration">

                    <label class="mr-2" >Sand Report Emails</label>

                    <input type="text" pInputText placeholder="Enter Emails separated by semicolon"
                        [(ngModel)]="sandReportEmails" (change)="updateSandReportEmails()" />

                </p-accordionTab>

                <!-- Addresses -->
                <p-accordionTab header="Addresses" [selected]="false">

                    <p-checkbox [(ngModel)]="shippingAddressIsTheSame" binary="true"
                        label="Shipping Address is the same as Billing Address"></p-checkbox>

                    <div class="grid field mt-3">

                        <div class="col-6">
                            <div>Billing Address</div>

                            <app-address [(ngModel)]="company.billingAddress" (emailValid)="validateBillingEmail($event)">
                            </app-address>
                        </div>
                        <div class="col-6">

                            <!-- Shipping Address -->

                            <div *ngIf="!shippingAddressIsTheSame">
                                <div>Shipping Address</div>

                                <app-address [(ngModel)]="company.shippingAddress"
                                    (emailValid)="validateShippingEmal($event)"></app-address>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
        <!-- Action Buttons -->

        <div class="col-12">

        <button pButton class="ui-button-primary m-2" *ngIf="isNew" (click)="saveCompany()" [ngClass]="{ 'action-button': !saving,
                                 'action-button-disabled': saving }" [disabled]="saving">Create
        </button>

        <button pButton class="ui-button-primary m-2" *ngIf="!isNew" (click)="saveCompany()" [ngClass]="{ 'action-button': !saving,
                                 'action-button-disabled': saving }" [disabled]="saving">Update
        </button>
        <button pButton class="action-button m-2" (click)="returnToCompanyList()">Cancel
        </button>
    </div>



        <!--Invalid Model Dialog-->
        <p-dialog header="Cannot Save Changes" [(visible)]="showModelInvalidDialog" [modal]="true"
            [style]="{width: '350px'}" [minY]="70" [baseZIndex]="999" [autoZIndex]="false" [dismissableMask]="true">
            <span>
                One or more fields is empty or invalid. Please correct and try again.
            </span>
            <p-footer>
                <button type="button" pButton icon="fa-check" (click)="closeInvalidModelDialog()" label="Ok"></button>
            </p-footer>
        </p-dialog>
    </div>
</div>