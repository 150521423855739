import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICompanyService,
         IDistrictService,
         IFeaturesService,
         ICompanyWorkbookConfigurationService,
         ICalculatedFieldConfigurationService,
         IApplicationContextService,
         IOpsViewerMessagesService,
         IUserService,
        } from '../../../services/services';
import { Company,
         Address,
         Features,
         CompanyWorkbookConfiguration,
         WorkbookMeasurementChannnelConfiguration,
         UnitChoice,
         WellKnownMeasurementChoice,
         UnitSystemChoice,
         WorkbookConfiguration,
         CompanyCalculatedFieldConfiguration,
         CompanyTypes} from '../../../models/models';
import { Message, SelectItem, ConfirmationService } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { ICurrentUserService } from 'src/app/services/currentUserService/icurrentuser.service';
import { firstValueFrom } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.css']
})

export class CompanyDetailComponent implements OnInit {
  public everythingReady = false;
  private companyReady = false;
  private companyId: string;
  public company: Company = null;
  public messages: Message[] = [];
  public isNew = false;
  public isAdmin = false;
  public categories: SelectItem[] = [];
  private categoriesReady = false;
  public districts: SelectItem[] = [];
  private districtReady = false;
  public addingDistrict = false;
  public newDistrictName: string;
  public billingAddressEmailValid = true;
  public shippingAddressEmailValid = true;
  public shippingAddressIsTheSame = true;
  public modelInvalid = false;
  public showModelInvalidDialog = false;
  public saving = false;

  public companyAdministrationFeatureEnabled = false;
  public featuresReady = false;

  public serviceProviderOptions: SelectItem[] = [];
  public serviceProvidersReady = false;

  public offlineWorkbookFeatureEnabled = false;
  public workbookConfigurationUnitSystemChoices: SelectItem[] = [
    {label: UnitSystemChoice.si, value: UnitSystemChoice.si  },
    {label: UnitSystemChoice.imperial, value: UnitSystemChoice.imperial  }
  ];
  public workbookWellKnownMeasurements: WellKnownMeasurementChoice[] = [];
  public workbookWellKnownMeasurementOptions: SelectItem[] = [];
  public workbookActivities: SelectItem[] = [];
  public workbookConfiguration: CompanyWorkbookConfiguration;
  public workbookWellKnownMeasurementsReady = false;
  public workbookActivitiesReady = false;
  public workbookConfigurationsReady = false;
  public workbookConfigurationsDataTypes: SelectItem[] = [
    { label: 'Number', value: 'Number' },
    { label: 'Text', value: 'Text' }
  ];
  public workbookConfigurationCols: any[] = [];
  public workbookConfigurationDisplayDialog = false;
  public workbookConfigurationUnitOptions: SelectItem[] = [];

  public companyCalculatedFieldConfiguration: CompanyCalculatedFieldConfiguration;

  public sandReportEmails: string;
  public autoApprovedEmailDomains: string;

  public companyTypes: SelectItem[] = [
    { label: CompanyTypes.Vendor, value: CompanyTypes.Vendor },
    { label: CompanyTypes.Consumer, value: CompanyTypes.Consumer }
  ];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _companyService: ICompanyService,
    private _districtService: IDistrictService,
    private _titleService: Title,
    private _featuresService: IFeaturesService,
    private _workbookConfigurationService: ICompanyWorkbookConfigurationService,
    private _calculatedFieldConfigurationService: ICalculatedFieldConfigurationService,
    private _applicationContextService: IApplicationContextService,
    private _messageService: IOpsViewerMessagesService,
    private _currentUserService: ICurrentUserService,
    private _userService: IUserService, ) { }

  ngOnInit() {
    this.getFeatures();
    this.getCompanyFromRoute();
    this.getServiceProviders();
    this.EnableRoleContent();
  }

  async EnableRoleContent() : Promise<void> {
    const email = this._currentUserService.getUserEmail();
    this.isAdmin = await firstValueFrom(this._userService.isAdmin(email));
  }

  getFeatures() {
    // Offline Workbook
    this._featuresService.getEnabledFeatures().subscribe(
      results => {
        this.offlineWorkbookFeatureEnabled = results.some(r => r === Features.OfflineWorkbook);
        this.companyAdministrationFeatureEnabled = results.some(r => r === Features.CompanyAdministration);
        this.featuresReady = true;
        this.checkIfEveryThingIsReady();
      },
      error => { this._messageService.errorMessage(error, 'Could not get features'); },
      () => {}
    );
  }

  getCompanyFromRoute() {
    this._route.params.subscribe(params => {
      this.companyId = params['id'];
      if (!this.companyId || this.companyId.toUpperCase() === 'NEW') {
        this.isNew = true;
        this.createNewCompany();
      } else {
        this.getCompany();
      }
      this.setPageTitle(this.companyId);
      // this.getCategories();
      // this.getDistricts();
    });
  }

  setPageTitle(companyId: string) {
    if (!companyId || companyId === 'New' || companyId === '') {
      this._titleService.setTitle('New Job');
    } else { this._titleService.setTitle(companyId); }
  }
  generateApiKey(){
    this.company.apiKey = this.generateUUID().replace(/-/g, '');
  }
  private generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }
  createNewCompany() {
    this.company = new Company(
      null,
      null,
      null,
      null,
      null,
      new Address(),
      new Address(),
      null
    );
    // All new companies now enabled for offline workbook
    this.company.enableOfflineWorkbook = true;

    this.companyReady = true;
    this.checkIfEveryThingIsReady();
  }

  getCompany() {
    this.messages = [];
    this._companyService.getCompany(this.companyId).subscribe(
      result => {
        if (result == null) {
          this.messages = [];
          this.messages.push({
            severity: 'error',
            summary: 'Could not find Company',
            detail: 'Company with Id (' + this.companyId + ') was not found'
          });
        } else {
          this.company = result;

          if (this.company.sandReportEmailAddresses) {
            this.sandReportEmails = this.company.sandReportEmailAddresses.join(';');
          }

          // prevent null address objects on page
          if (!this.company.billingAddress) { this.company.billingAddress = new Address(); }
          if (!this.company.shippingAddress) { this.company.shippingAddress = new Address(); }
          // Auto-check same address button if needed
          if (this.company.billingAddress !== this.company.shippingAddress) {
            this.shippingAddressIsTheSame = false;
          }

          // For old companies set companyType
          // TODO: Remove after Data Migration
          if (this.companyAdministrationFeatureEnabled && this.company.companyType === null) {
            this.company.companyType = CompanyTypes.Vendor;
          }

          if (this.company.autoApprovedEmailDomains) {
            this.autoApprovedEmailDomains = this.company.autoApprovedEmailDomains.join(';');
          }

          // All new companies now enabled for offline workbook, force set any existing companies
          // that do not have this already set
          this.company.enableOfflineWorkbook = true;

          this.companyReady = true;
          this.checkIfEveryThingIsReady();
        }
      },
      error => { this._messageService.errorMessage(error, 'Could not get Company'); }
    );
  }

  getServiceProviders() {
    this._companyService.getCompanies().subscribe(
      result => {
        const serviceProviders = result.filter(r => r.allowedToBeServiceProvider);
        serviceProviders.forEach(r => {
           this.serviceProviderOptions.push({
            label: r.name,
            value: r.id
          });
        });
      },
      error => { this._messageService.errorMessage(error, 'Could not get Service Providers'); },
      () => {
        this.serviceProvidersReady = true;
        this.checkIfEveryThingIsReady();
      }
    );
  }

  getCategories() {
    this._companyService.getCompanyCategories().subscribe(
      results => {
        results.forEach(result => {
          this.categories.push({
            label: result,
            value: result
          });
        });
      },
      error => { this._messageService.errorMessage(error, 'Could not get Company Categories'); },
      () => { // complete, error on categories doesn't prevent the rest from continuing
        this.categoriesReady = true;
        this.checkIfEveryThingIsReady();
      }
    );
  }

  getDistricts() {
    this._districtService.getDistricts().subscribe(
      results => {
        results.forEach(result => {
          this.districts.push({
            label: result.name,
            value: result.id
          });
        }); },
        error => { this._messageService.errorMessage(error, 'Could not get Districts'); },
        () => { // complete, error on districts doesn't prevent the rest from continuing
          this.districtReady = true;
          this.checkIfEveryThingIsReady();
        }
      );
  }

  checkIfEveryThingIsReady() {
    this.everythingReady = this.companyReady &&
                           this.featuresReady &&
                           this.serviceProvidersReady;
  }

  addDistrictDialog() {
    this.addingDistrict = true;
  }

  addDistrict() {
    this._districtService.createDistrict(this.newDistrictName).subscribe(
      results => {},
      error => { this._messageService.errorMessage(error, 'Could not create District'); },
      () => {
        this.addingDistrict = false;
      });
  }

  closeDistrictDialog() {
    this.addingDistrict = false;
  }

  handleErrors(event: any) {
    this.messages = event;
  }

  updateWorkbookConfiguration(event: any) {
    this.workbookConfiguration = event;
  }

  updateCompanyCalculations(event: any) {
    this.companyCalculatedFieldConfiguration = event;
  }

  updateSandReportEmails() {
    if (this.sandReportEmails) {
      this.company.sandReportEmailAddresses = this.sandReportEmails.split(';');
    }
  }

  updateEmailDomains() {
    if (this.autoApprovedEmailDomains) {
      this.company.autoApprovedEmailDomains = this.autoApprovedEmailDomains.split(';');
    }
  }

  setAllowedToBeServiceProvider() {
    if (this.company.companyType === CompanyTypes.Vendor) {
      this.company.allowedToBeServiceProvider = true;
    } else {
      this.company.allowedToBeServiceProvider = false;
    }
  }

  validateCompanyModel(): boolean {
      if (!this.company.name) { return false; }
      if (this.companyAdministrationFeatureEnabled && !this.company.companyType) { return false; }
      // TODOD: Are any of the below required?  If so uncomment.
      //       Also uncomment validation messages in html
      // if(!this.company.category) return false;
      // if(!this.company.district) return false;
      // if(!this.validateBillingAddress()) return false;
      // if(!this.validateShippingAddress()) return false;
      // if(!this.validateEmails()) return false;

      return true;
  }

  validateBillingAddress() {
    if (!this.company.billingAddress.name) { return false; }
    if (!this.company.billingAddress.email) { return false; }
    if (!this.company.billingAddress.phone) { return false; }
    if (!this.company.billingAddress.street) { return false; }
    if (!this.company.billingAddress.city) { return false; }
    if (!this.company.billingAddress.state) { return false; }
    if (!this.company.billingAddress.zip) { return false; }

    return true;
  }

  validateShippingAddress() {
    if (!this.company.shippingAddress.name) { return false; }
    if (!this.company.shippingAddress.email) { return false; }
    if (!this.company.shippingAddress.phone) { return false; }
    if (!this.company.shippingAddress.street) { return false; }
    if (!this.company.shippingAddress.city) { return false; }
    if (!this.company.shippingAddress.state) { return false; }
    if (!this.company.shippingAddress.zip) { return false; }

    return true;
  }

  validateBillingEmail(event: any) {
    this.billingAddressEmailValid = event;
  }

  validateShippingEmail(event: any) {
    this.shippingAddressEmailValid = event;
  }

  validateEmails(): boolean {
    return this.billingAddressEmailValid && this.shippingAddressEmailValid;
  }

  saveCompany() {
    this.saving = true;
    if (this.shippingAddressIsTheSame && this.company) {
      this.company.setShippingAddress(this.company.billingAddress);
    }

    this.showModelInvalidDialog = this.modelInvalid = !this.validateCompanyModel();

    if (!this.showModelInvalidDialog) {
      if (this.shippingAddressIsTheSame) {
        this.company.shippingAddress = this.company.billingAddress;
      }

      if (this.isNew) {
        this._companyService.createCompany(this.company).subscribe(
          result => {
            this._applicationContextService.alertCompaniesListUpdated();
            if (this.offlineWorkbookFeatureEnabled && this.company.enableOfflineWorkbook) {
              this.saveWorkboookConfiguration();
            } else {
              this.returnToCompanyList();
            }
          },
          error => { this._messageService.errorMessage(error, 'Could not Create Company'); }
        );
      } else {
        this._companyService.updateCompany(this.companyId, this.company).subscribe(
          result => {
            this._applicationContextService.alertCompaniesListUpdated();
            if (this.offlineWorkbookFeatureEnabled && this.company.enableOfflineWorkbook) {
              this.saveWorkboookConfiguration();
            } else {
              this.returnToCompanyList();
            }
           },
           error => { this._messageService.errorMessage(error, 'Could not Update Company'); }
        );
      }
      this.saveCalculatedFieldConfiguration();
    } else {
      this.saving = false;
    }
  }

  async RecreateWorkbookConfiguration() : Promise<void> {
    if(!this.company.unitSystem) {
      console.error('Could not recreate Workbook configuration because the Unit System is no set.');
      return;
    }
    let wbConfigId: string = null;
    if(this.workbookConfiguration) {
      wbConfigId = this.workbookConfiguration.id;
    }
    this.saving = true;
    const newWbConfig = await this.createDefaultCompanyWorkbookConfiguration(wbConfigId, this.companyId, this.company.unitSystem);
    this.workbookConfiguration = newWbConfig;
    this.saveWorkboookConfiguration();
    this.saving = false;
  }

  async createDefaultCompanyWorkbookConfiguration(wbConfigurationId: string, companyId: string, unitSystem: string): Promise<CompanyWorkbookConfiguration> {
    const configuration = new CompanyWorkbookConfiguration(wbConfigurationId, companyId);
    const activityNames = await firstValueFrom(this._workbookConfigurationService.getActivities());
    //const workbookActivities = activityNames.map(i => <SelectItem>{label: i, value: i});
    const workbookWellKnownMeasurements = await firstValueFrom(this._workbookConfigurationService.getWellKnownMeasurements());

    configuration.setDefaultMeasurements(activityNames, unitSystem, workbookWellKnownMeasurements);
    return configuration;
  }

  saveWorkboookConfiguration() {
    this._workbookConfigurationService.createOrUpdateConfiguration(this.workbookConfiguration).subscribe(
      result => {
        this.returnToCompanyList();
      },
      error => { this._messageService.errorMessage(error, 'Could not Create or Update Company Workbookconfiguration'); }
    );
  }

  saveCalculatedFieldConfiguration() {
    if (this.companyCalculatedFieldConfiguration) {
      if (this.companyCalculatedFieldConfiguration.companyId === undefined) {
        this.companyCalculatedFieldConfiguration.companyId = this.companyId;
      }
      this._calculatedFieldConfigurationService.createOrUpdateConfiguration(this.companyCalculatedFieldConfiguration).subscribe(
        result => {
          this.returnToCompanyList();
        },
        error => { this._messageService.errorMessage(error, 'Could not Create or Update Company Calculated Field Configuration'); }
      );
    }
  }

  closeInvalidModelDialog() {
    this.showModelInvalidDialog = false;
  }

  returnToCompanyList() {
    this._router.navigate(['/companies/']);
  }

  showApiHelp() {
    window.open('/api-help', '_blank');
  }

}
