<div class="grid field col-12">
  <div *ngIf="loading || saving" class="grid field col-12">
    <p-progressSpinner></p-progressSpinner>
  </div>

  <div *ngIf="!loading">
    <div class="grid field col-12">
      <!--Page Title and Dashboard Link-->
      <div class="col-12 md:col-2">
        <h2>Operator Job Details</h2>
      </div>
      <div class="col-12 md:col-2">
        <button pButton  (click)="navigateToDashboard()" icon="far fa-chart-bar" label="Dashboard"
          style="margin-top:17px; height:30px; width:145px; float:right; vertical-align:bottom;">
        </button>
      </div>
      <div class="col-12 md:col-2">
        <button pButton  (click)="showManageFiles = true" icon="far fa-chart-bar" label="Files"
          style="margin-top:17px; height:30px; width:145px; float:right; vertical-align:bottom;">
        </button>
      </div>
  
      <div class="grid field col-12">
        <div class="col-12 md:col-2">
          <label>Job Number</label>
        </div>
        <div class="col-12 md:col-6">
          <label class="readOnly-label">{{job.number}}</label>
        </div>
      </div>
      <div class="grid field col-12">
        <div class="col-12 md:col-2">
          <label>Company</label>
        </div>
        <div class="col-12 md:col-6">
          <label class="readOnly-label">{{job.consumerCompanyName}}</label>
        </div>
      </div>
      <div class="grid field col-12">
        <div class="col-12 md:col-2">
          <label>Well Api Number</label>
        </div>
        <div class="col-12 md:col-6">
          <label class="readOnly-label">{{job.wellApiNumber}}</label>
        </div>
      </div>
      <div class="grid field col-12">
        <div class="col-12 md:col-2">
          <label>Arrive on Loc/Begin RU on Well</label>
        </div>
        <div class="col-12 md:col-6">
          <label class="readOnly-label">{{job.startTime | date:'short'}}</label>
        </div>
      </div>
      <div class="grid field col-12">
        <div class="col-12 md:col-2">
          <label>Release from Loc/RD from Well</label>
        </div>
        <div class="col-12 md:col-6">
          <label class="readOnly-label">{{job.endTime | date:'short'}}</label>
        </div>
      </div>
      <div class="grid field col-12">
          <div class="col-12 md:col-2">
            <label>Job Type</label>
          </div>
          <div class="col-12 md:col-6">
            <label class="readOnly-label">{{job.workbookType}}</label>
          </div>
        </div>
        <div class="grid field col-12">
          <div class="col-12 md:col-2">
            <label>Service Providers</label>
          </div>
          <div class="col-12 md:col-6">
            <p-multiSelect [options]="vendorCompanies" [(ngModel)]="selectedVendorCompanyIds" (onChange)="vendorCompanyIdsChanged()"></p-multiSelect>
          </div>
        </div>
    </div>


    <div *ngIf="vendorJobsReady" class="grid field col-12">
      <p-accordion>
        <p-accordionTab *ngFor="let vendorJob of vendorJobs" header="{{getVendorCompanyName(vendorJob)}} - {{vendorJob.name}}">
            <app-vendor-job-common [ngModel]="vendorJob" [disabled]="true" [showWorkbookLink]="true" [consumerJobId]="job.id" [securityToken]="securityToken"></app-vendor-job-common>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</div>

<!-- Files Dialog -->
<p-dialog *ngIf="showManageFiles" header="Job Files" [(visible)]="showManageFiles">
  <app-filelist [consumerJobId]="jobId" [isVendorJob]="false"></app-filelist>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="showManageFiles = false" label="Close"></button>
</p-footer>
</p-dialog>