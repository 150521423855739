<div class="grid field col-12">
    <div class="grid field col-12">
        <h1>Workbook Log</h1>
        <p-progressSpinner *ngIf="showSavingProgress || !everythingReady"></p-progressSpinner>
    </div>
    <div class="grid field col-12" *ngIf="messages.length > 0">
        <p-messages [(value)]="messages"></p-messages>
    </div>
    <div class="grid field col-12" *ngIf="everythingReady">
        <div class="col-12 md:col-2">
            <button pButton  (click)="navigateToJobDetails()" icon="fa fa-edit" label="Job Details" style="max-width:145px; vertical-align:bottom;"></button>
        </div>
        <div class="col-12 md:col-2">
            <button pButton  (click)="navigateToDashboard()" icon="fa fa-bar-chart" label="Dashboard" style="max-width:145px; vertical-align:bottom;"></button>
        </div>
        <div class="col-12 md:col-2"></div>
        <div class="col-12 md:col-2">
            <button pButton type="button" label="&nbsp;&nbsp;Configure" (click)="openWorkbookConfiguration()" icon="fa fa-cog" style="width:45%; max-width: 140px; float:right; vertical-align:bottom;"></button>
        </div>
        <div class="col-12 md:col-2">
            <button pButton type="button" label="&nbsp;&nbsp;Export" (click)="dt.exportCSV()" icon="fa fa-download" style="width:45%; max-width: 140px; float:right; vertical-align:bottom;"></button>
        </div>
            <div class="grid field col-12">
                    <p-table #dt 
                    [columns]="scrollableCols" [resizableColumns]="true"
                    [scrollable]="true" scrollHeight="scrollHeight"
                    [frozenColumns]="frozenCols" frozenWidth="175px"
                    [value]="workbookLogs"  
                    [loading]="!workbookReady"
                    (onEditInit)="notifyChangesMade($event)">
                    <!-- (onEditComplete)="tableDataChanged($event)"> -->
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <!-- <col *ngFor="let col of columns" [style.width]="col.width" /> -->
                                <col *ngFor="let col of columns" [style.width]="setWidth(col)"/>
                            </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <!-- Addding 'pResizableColumn' here doesn't work properly -->
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'display':col.display, 'height':'100px', 'overflow':'hidden'}">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-workbookLog let-columns="columns">
                            <tr style="height:30px;">
                                <td *ngFor="let col of columns" [pEditableColumn]="workbookLog" [pEditableColumnField]="col.field" [ngStyle]="{'display':col.display}">
                                    <p-cellEditor *ngIf="col.field != 'relative_time' && col.header != 'Timestamp'">
                                        <ng-template pTemplate="input">
                                            <input *ngIf="(col.field != 'time') && (col.field != 'fluid_system') && (col.field != 'activity')" type="text" [(ngModel)]="workbookLog[col.field]"/>
                                            <p-calendar *ngIf="col.field == 'time'"
                                                [(ngModel)]="workbookLog.calendarTime" 
                                                [showIcon]="true" 
                                                [showTime]="true"
                                                [hourFormat]="'12'" 
                                                [monthNavigator]="true"
                                                [yearNavigator]="true" 
                                                yearRange="2018:2038"
                                                appendTo="body"
                                                >
                                            </p-calendar>
                                            <p-dropdown *ngIf="col.field == 'fluid_system'" [options]="fluidSystems" [(ngModel)]="workbookLog[col.field]" placeholder="Select" appendTo="body"></p-dropdown>
                                            <p-dropdown *ngIf="col.field == 'activity'" [options]="activities" [(ngModel)]="workbookLog[col.field]" placeholder="Select" appendTo="body"></p-dropdown>
                                            <!-- <input type="text" [(ngModel)]="workbookLog[col.field]"/> -->
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div *ngIf="col.field == 'time'" class="wbCell">{{workbookLog.calendarTime | date: 'short'}}</div>
                                            <div *ngIf="col.field != 'time'" class="wbCell" 
                                                [pTooltip]="workbookLog[col.field]" tooltipPosition="top" tooltipEvent="hover">{{workbookLog[col.field]}}</div>
                                        </ng-template>
                                    </p-cellEditor>
                                    <div *ngIf="col.field == 'relative_time'">
                                            {{workbookLog[col.field]  | number:'1.1-2'}}
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div class="grid field col-12">
                                <div class="grid field col-12" pTooltip="Editing of Old Workbook has been disabled.  Only jobs with new workbook can add or modify workbook data.">
                                    <button type="button" [disabled]="true" pButton icon="fa fa-plus" (click)="addWorkbookLogRow()" label="Add" style="width:100px; float:left;"></button>
                                    <button [disabled]="true" type="button" pButton icon="fa fa-save" (click)="saveWorkbookChanges()" label="Save" style="width:100px; float:left;"></button>
                                    <!-- <button type="button" pButton icon="fa fa-plus" (click)="addWorkbookLogRow()" label="Add" style="width:100px; float:left;"></button> -->
                                    <!-- <button [disabled]="!changesMade" type="button" pButton icon="fa fa-save" (click)="saveWorkbookChanges()" label="Save" style="width:100px; float:left;"></button> -->
                                </div>
                            </div>
                                
                            </ng-template>
                    </p-table>
                </div>
    </div>
</div>



<p-dialog *ngIf="workbookReady" header="Workbook Configuration Settings" [(visible)]="showWorkbookConfigurationDialog">
    <!-- Displayed Workbook Fields -->
    <div class="grid field col-12">
            <p-table #displayFieldsDT [value]="workbookConfiguration.displayFields">
                    <ng-template pTemplate="colgroup">
                            <colgroup>
                                <col [style.width]="fieldNameWidth" />
                                <col [style.width]="buttonWidth" />
                            </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header">
                            <tr>
                                <th>Workbook Fields</th>
                                <th></th>
                            </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-field>
                            <tr *ngIf="field != 'time' && field != 'relative_time'">
                                <td>{{field}}</td>
                                <td><button pButton type="button" label="Hide" (click)="hideDisplayedField(field)" style="width:75px"></button></td>
                            </tr>
                        </ng-template>
            </p-table>
    </div>
    <!-- Available Workbook Fields -->
    <div class="grid field col-12">
        <p-table #availableFieldsDT [value]="['one row']">
                <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col [style.width]="fieldNameWidth" />
                            <col [style.width]="buttonWidth" />
                        </colgroup>
                </ng-template>
                <ng-template pTemplate="header">
                        <tr>
                            <th>Add Fields</th>
                            <th></th>
                        </tr>
                </ng-template>
                <ng-template pTemplate="body" let-row>
                        <tr>
                            <td><p-dropdown [options]="availableFieldOptions" [(ngModel)]="selectedAvailableField" placeholder="Select a Field" appendTo="body" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown></td>
                            <td><button pButton type="button" label="Add" (click)="displayField()" style="width:75px"></button></td>
                        </tr>
                </ng-template>
        </p-table>
    </div>
    <!-- Add New Workbook Field -->
    <div class="grid field col-12">
        <p-table #createFieldsDT [value]="['one row']" [resizableColumns]="true">
                <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col [style.width]="createFieldWidth" />
                            <col [style.width]="createFieldIsTextWidth" />
                            <col [style.width]="createFieldButtonWidth" />
                        </colgroup>
                </ng-template>
                <ng-template pTemplate="header">
                        <tr>
                            <th>Create Fields</th>
                            <th>Text?</th>
                            <th></th>
                        </tr>
                </ng-template>
                <ng-template pTemplate="body" let-row>
                        <tr>
                            <td><input type="text" pInputText [(ngModel)]="createdField" placeholder="New Field" style="width:100%"/></td>
                            <td><p-checkbox [(ngModel)]="createFieldIsText" binary="true"></p-checkbox></td>
                            <td><button pButton type="button" label="Create" (click)="createField()" style="width:75px"></button></td>
                        </tr>
                </ng-template>
        </p-table>
    </div>
</p-dialog>
